import React from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { UserCard, CloseableModalWithHeader } from '../../components';
import { EnquiryForm } from '../../forms';

import css from './ListingPage.module.css';

const SectionHostMaybeComponent = props => {
  const {
    title,
    listing,
    authorDisplayName,
    onContactUser,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSubmitEnquiry,
    currentUser,
    onManageDisableScrolling,
    intl,
  } = props;

  if (!listing.author) {
    return null;
  }

  const modalTitle = intl.formatMessage(
    { id: 'EnquiryForm.heading' },
    { listingTitle: title }
  );

  return (
    <div id="host" className={css.sectionHost}>
      <h2 className={css.yourHostHeading}>
        <FormattedMessage id="ListingPage.yourHostHeading" />
      </h2>
      <UserCard user={listing.author} currentUser={currentUser} onContactUser={onContactUser} />
      <CloseableModalWithHeader
        title={modalTitle}
        id="ListingPage.enquiry"
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <EnquiryForm
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
        />
      </CloseableModalWithHeader>
    </div>
  );
};

const SectionHostMaybe = compose(injectIntl)(SectionHostMaybeComponent);

export default SectionHostMaybe;
